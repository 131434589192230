body {
  margin: 0;
  padding: 0;
  font-family: "Courier New", Courier, monospace;
  font-size: 16px;
  line-height: 1.5;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #3c3836;
}

::-webkit-scrollbar-thumb {
  background: #504945;
}

::-webkit-scrollbar-thumb:hover {
  background: #665c54;
}
